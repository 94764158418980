import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,[_c(VCardTitle,{staticClass:"d-flex justify-center"},[_c('h4',[_vm._v(" Riwayat Automasi Workspace ("+_vm._s(_vm.currentWorkspace.name)+") ")])]),_c(VCardText,[_c(VRow,[_c(VCol,{attrs:{"cols":"12","sm":"6"}},[_c('div',{staticClass:"text-caption-2 mb-1"},[_vm._v(" Filter Tanggal ")]),_c('DateRangePicker',{ref:"dateRangePicker",staticClass:"mb-4",attrs:{"max-date":_vm.today},on:{"update":_vm.changeFilterDate},model:{value:(_vm.filterDate),callback:function ($$v) {_vm.filterDate=$$v},expression:"filterDate"}})],1),_c(VCol,{attrs:{"cols":"12","sm":"6"}},[_c('div',{staticClass:"text-caption-2 mb-1"},[_vm._v(" Filter Status ")]),_c(VSelect,{attrs:{"items":_vm.statusOptions,"item-text":"label","item-value":"value","outlined":"","dense":"","flat":"","hide-details":"auto"},on:{"change":_vm.loadWorkflowLog},model:{value:(_vm.filterLog.status),callback:function ($$v) {_vm.$set(_vm.filterLog, "status", $$v)},expression:"filterLog.status"}})],1)],1),(_vm.filterLog.status !== null || _vm.filterLog.date_min || _vm.filterLog.date_max)?_c(VChip,{staticClass:"mb-4",attrs:{"small":"","close":"","color":"error"},on:{"click:close":_vm.resetFilter}},[_vm._v(" Reset Filter ")]):_vm._e(),_c(VDataTable,{staticClass:"mx-5 my-5",attrs:{"fixed-header":"","item-key":"id","height":"58vh","headers":_vm.tableColumns,"items":_vm.workflowLogList,"mobile-breakpoint":"0","server-items-length":_vm.workflowLogCount,"disable-sort":"","footer-props":{
        'items-per-page-options': [10, 25, 50],
        'items-per-page-text': 'Jumlah data',
      },"options":_vm.paginationLog,"loading":_vm.loadingLogWorkflow},on:{"update:options":function($event){_vm.paginationLog=$event}},scopedSlots:_vm._u([{key:"item.executed_at",fn:function(props){return [_vm._v(" "+_vm._s(_vm.formatDate(props.item.executed_at, { month: 'short', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric' }))+" ")]}},{key:"item.workflow",fn:function(props){return [_c('router-link',{attrs:{"to":{
            name: 'workflow-detail',
            params: {
              id: props.item.trigger_id,
            },
          }}},[_c('span',[_vm._v(_vm._s(props.item.trigger_name))])])]}},{key:"item.creator",fn:function(props){return [_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c(VAvatar,_vm._g({attrs:{"size":"36","outlined":"","color":"warning"}},on),[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.avatarText(props.item.creator_name)))])])]}}],null,true)},[_c('span',[_vm._v(_vm._s(props.item.creator_name))])])]}},{key:"item.action",fn:function(props){return [_c('span',[_vm._v(_vm._s(props.item.object_name))]),_c(VIcon,{staticClass:"mx-2",attrs:{"size":"16"}},[_vm._v(" "+_vm._s(_vm.icons.mdiArrowRight)+" ")]),_c('span',[_vm._v(_vm._s(props.item.action_name))])]}},{key:"item.status",fn:function(props){return [_c(VChip,{attrs:{"color":props.item.is_error ? 'error' : 'success',"outlined":""}},[_vm._v(" "+_vm._s(props.item.is_error ? 'Error' : 'Success')+" ")])]}},{key:"item.message",fn:function(props){return [_c('span',[_vm._v(_vm._s(props.item.message ? props.item.message : '-'))])]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }