<template>
  <v-card>
    <v-card-title class="d-flex justify-center">
      <h4>
        Riwayat Automasi Workspace ({{ currentWorkspace.name }})
      </h4>
    </v-card-title>

    <v-card-text>
      <v-row>
        <v-col
          cols="12"
          sm="6"
        >
          <div class="text-caption-2 mb-1">
            Filter Tanggal
          </div>
          <DateRangePicker
            ref="dateRangePicker"
            v-model="filterDate"
            class="mb-4"
            :max-date="today"
            @update="changeFilterDate"
          />
        </v-col>
        <v-col
          cols="12"
          sm="6"
        >
          <div class="text-caption-2 mb-1">
            Filter Status
          </div>
          <v-select
            v-model="filterLog.status"
            :items="statusOptions"
            item-text="label"
            item-value="value"
            outlined
            dense
            flat
            hide-details="auto"
            @change="loadWorkflowLog"
          />
        </v-col>
      </v-row>
      <v-chip
        v-if="filterLog.status !== null || filterLog.date_min || filterLog.date_max"
        class="mb-4"
        small
        close
        color="error"
        @click:close="resetFilter"
      >
        Reset Filter
      </v-chip>
      <v-data-table
        fixed-header
        item-key="id"
        height="58vh"
        class="mx-5 my-5"
        :headers="tableColumns"
        :items="workflowLogList"
        mobile-breakpoint="0"
        :server-items-length="workflowLogCount"
        disable-sort
        :footer-props="{
          'items-per-page-options': [10, 25, 50],
          'items-per-page-text': 'Jumlah data',
        }"
        :options.sync="paginationLog"
        :loading="loadingLogWorkflow"
      >
        <template v-slot:[`item.executed_at`]="props">
          {{ formatDate(props.item.executed_at, { month: 'short', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric' }) }}
        </template>
        <template v-slot:[`item.workflow`]="props">
          <router-link
            :to="{
              name: 'workflow-detail',
              params: {
                id: props.item.trigger_id,
              },
            }"
          >
            <span>{{ props.item.trigger_name }}</span>
          </router-link>
        </template>
        <template v-slot:[`item.creator`]="props">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-avatar
                size="36"
                outlined
                color="warning"
                v-on="on"
              >
                <span class="font-weight-bold">{{ avatarText(props.item.creator_name) }}</span>
              </v-avatar>
            </template>
            <span>{{ props.item.creator_name }}</span>
          </v-tooltip>
        </template>
        <template v-slot:[`item.action`]="props">
          <span>{{ props.item.object_name }}</span>
          <v-icon
            size="16"
            class="mx-2"
          >
            {{ icons.mdiArrowRight }}
          </v-icon>
          <span>{{ props.item.action_name }}</span>
        </template>
        <template v-slot:[`item.status`]="props">
          <v-chip
            :color="props.item.is_error ? 'error' : 'success'"
            outlined
          >
            {{ props.item.is_error ? 'Error' : 'Success' }}
          </v-chip>
        </template>
        <template v-slot:[`item.message`]="props">
          <span>{{ props.item.message ? props.item.message : '-' }}</span>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>
<script>
import { mdiArrowRight } from '@mdi/js'
import { onMounted, ref, watch } from '@vue/composition-api'
import useAutomationV2 from '@/composables/useAutomation'
import store from '@/store'
import DateRangePicker from '@/components/inputs/DateRangePicker.vue'
import { avatarText, formatDate } from '@core/utils/filter'

export default {
  components: {
    DateRangePicker,
  },
  setup() {
    const {
      fetchWorkflowLog, loadingLogWorkflow, workflowLogList, workflowLogCount,
    } = useAutomationV2()

    const currentWorkspace = store.state.workspace.selectedWorkspace.workspace
    const dateRangePicker = ref(null)
    const today = new Date()
    const filterDate = { startDate: null, endDate: null }
    const statusOptions = ref([
      { label: 'Semua', value: null },
      { label: 'Success', value: false },
      { label: 'Error', value: true },
    ])

    const tableColumns = ref([
      {
        text: 'Tanggal',
        value: 'executed_at',
      },
      {
        text: 'Workflow',
        value: 'workflow',
      },
      {
        text: 'Dibuat Oleh',
        value: 'creator',
      },
      {
        text: 'Aksi',
        value: 'action',
      },
      {
        text: 'Status',
        value: 'status',
      },
      {
        text: 'Keterangan',
        value: 'message',
      },
    ])

    const paginationLog = ref({})

    const filterLog = ref({
      sort: 'executed_at', sort_type: 'DESC', date_min: null, date_max: null, status: null,
    })

    const loadWorkflowLog = () => {
      fetchWorkflowLog({
        limit: paginationLog.value.itemsPerPage ? paginationLog.value.itemsPerPage : 10,
        offset: paginationLog.value.page ? (paginationLog.value.page - 1) * paginationLog.value.itemsPerPage : 0,
      }, filterLog.value)
    }

    const changeFilterDate = val => {
      filterLog.value = {
        ...filterLog.value,
        date_min: val.startDate ? val.startDate.toISOString() : null,
        date_max: val.endDate ? val.endDate.toISOString() : null,
      }
      loadWorkflowLog()
    }

    const resetFilter = () => {
      filterLog.value = {
        sort: 'executed_at', sort_type: 'DESC', date_min: null, date_max: null, status: null,
      }
      filterDate.value = { startDate: null, endDate: null }
      dateRangePicker.value.reset()
      loadWorkflowLog()
    }

    onMounted(() => {
      loadWorkflowLog()
    })

    watch(paginationLog, () => {
      loadWorkflowLog()
    })

    return {
      today,
      filterDate,
      statusOptions,
      loadingLogWorkflow,
      workflowLogList,
      workflowLogCount,
      paginationLog,
      filterLog,
      currentWorkspace,
      dateRangePicker,

      loadWorkflowLog,
      changeFilterDate,

      icons: {
        mdiArrowRight,
      },
      tableColumns,
      avatarText,
      formatDate,
      resetFilter,
    }
  },
}
</script>
